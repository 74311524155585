module.exports = {
  siteTitle: 'AD Ingeniería', // <title>
  manifestName: 'ADIngenieria',
  manifestShortName: 'AD Ingenieria', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'AD Ingeniería',
  heading: '',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/antonio-donado-campos-9141a731/',
    },
    {
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:ad@adingenieria.net',
    },
  ],
};
